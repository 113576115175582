.spinner {
  width: 150px; /* control the size */
  aspect-ratio: 1;
  display: grid;
  mask: conic-gradient(from 22deg, #0003, #000);
  animation: load 1s steps(8) infinite;
  text-align: center;
  margin: 0 auto;
}
.spinner,
.spinner:before {
  --_g: linear-gradient(#355E3B 0 0) 50%; /* update the color here */
  background:
    var(--_g)/34% 8%  space no-repeat,
    var(--_g)/8%  34% no-repeat space;
}
.spinner:before {
  content: "";
  transform: rotate(45deg);
}
@keyframes load {
  to { transform: rotate(1turn); }
}

